// ArrowDownIcon.js
import React from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { styled, keyframes } from "@mui/material/styles";

// Definimos la animación de salto
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0); // Posición inicial y final
  }
  50% {
    transform: translateY(-10px); // Sube 10px en el punto medio de la animación
  }
`;

// Estilos personalizados usando styled de MUI
const StyledIcon = styled(KeyboardDoubleArrowDownIcon)({
  fontSize: "2rem", // Tamaño del icono
  color: "#ffffff", // Color blanco del icono
  backgroundColor: "#007bff", // Fondo azul
  border: "2px solid #0056b3", // Borde azul oscuro
  borderRadius: "20%", // Bordes redondeados
  transition: "transform 0.3s ease", // Animación al hacer hover
  cursor: "pointer", // Manito al pasar el cursor
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Sombra para el icono
  animation: `${bounce} 1s ease-in-out infinite`, // Aplica la animación de salto
});

const ArrowDownIcon = () => {
  return <StyledIcon />;
};

export default ArrowDownIcon;
