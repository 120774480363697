import React, { useState } from "react";
import { CheckCircle } from "lucide-react";

// Mueve `data` fuera del componente `TableAdjuntos`
export const data = [
  { id: 1, document: "RUT", link_up: "Link...", btn_adjunto: "" },
  {
    id: 2,
    document:
      "Certificado de Existencio y Representación Legal de la Persona Jurídica",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 3,
    document: "Cédula del Representante Legal",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 4,
    document: "Estados Financieros",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 5,
    document: "Certificación Bancaria",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 6,
    document: "Referencia Comercial",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 7,
    document: "Certificado SG-SST",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 8,
    document: "Contrato, Tarifario o Cotización y Portafolio de Servicios",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 9,
    document: "Plan de Contingencias",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 10,
    document: "Autorización Tratamiento de Datos Personales",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 11,
    document: "Hoja de vida Proveedor",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 12,
    document: "Acuerdo Comercial, Operativos y Seguridad",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 13,
    document: "Código de Ética y Conducta de Proveedores",
    link_up: "Link...",
    btn_adjunto: "",
  },
  { id: 14, document: "Circular 170", link_up: "Link...", btn_adjunto: "" },
  {
    id: 15,
    document:
      "Declaración de Compromiso Prevención, Mitigación en la Cadena de Suministro",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 16,
    document: "Acta de Entrega Documentos Asociado de Negocio - Proveedor",
    link_up: "Link...",
    btn_adjunto: "",
  },
];

const TableAdjuntos = () => {
  const [uploadedFiles, setUploadedFiles] = useState({});

  // Maneja el cambio de archivo
  const handleFileChange = (event, rowId) => {
    event.stopPropagation();
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);

      // Obtiene el nombre del documento correspondiente al rowId
      const documentName =
        data.find((doc) => doc.id === rowId)?.document || file.name;

      setUploadedFiles((prev) => ({
        ...prev,
        [rowId]: { name: documentName, url: fileURL },
      }));
    }
  };

  // Abre el input de archivo al hacer clic en el botón de adjuntar
  const handleFileClick = (rowId) => {
    document.getElementById(`file-input-${rowId}`).click();
  };

  // Renderizadores personalizados para cada columna
  const customRenderers = {
    document: (row) => <span className="link-cell">{row.document}</span>,
    btn_adjunto: (row) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="file"
          style={{ display: "none" }}
          id={`file-input-${row.id}`}
          onChange={(e) => handleFileChange(e, row.id)}
        />
        <button
          className="attach-button"
          type="button"
          onClick={() => handleFileClick(row.id)}
        >
          Adjuntar archivo
        </button>
        {uploadedFiles[row.id] && (
          <CheckCircle
            size={20}
            color="green"
            style={{ marginLeft: "8px" }}
            title="Archivo adjuntado"
          />
        )}
      </div>
    ),
    link_up: (row) =>
      uploadedFiles[row.id] ? (
        <a
          href={uploadedFiles[row.id].url}
          target="_blank"
          rel="noopener noreferrer"
          className="link-cell"
        >
          {uploadedFiles[row.id].name}
        </a>
      ) : (
        <span className="link-cell">Link...</span>
      ),
  };

  // Columnas de la tabla
  const columns = [
    { width: 100, label: "Documentos", dataKey: "document" },
    { width: 100, label: "Link Up", dataKey: "link_up" },
    { width: 70, label: "Adjuntos", dataKey: "btn_adjunto" },
  ];

  return { customRenderers, columns, data, uploadedFiles, setUploadedFiles };
};

export default TableAdjuntos;
