import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import Cookies from "js-cookie";
import { useAuth as useAuthHook } from "../hooks/useAuth";

const AuthContext = createContext();

const REFRESH_INTERVAL = 55 * 60 * 1000; // Intervalo de refresco de 55 minutos

export const AuthProvider = ({ children }) => {
  const auth = useAuthHook();
  const [, setToken] = useState(Cookies.get("token"));
  const [, setRefreshToken] = useState(Cookies.get("refreshToken"));
  const refreshTimerRef = useRef(null);

  const startRefreshTimer = () => {
    if (refreshTimerRef.current) {
      clearInterval(refreshTimerRef.current);
    }

    refreshTimerRef.current = setInterval(async () => {
      const success = await auth.refreshAuthToken();

      if (!success) {
        clearInterval(refreshTimerRef.current);
        refreshTimerRef.current = null;
      }
    }, REFRESH_INTERVAL);
  };

  const stopRefreshTimer = () => {
    if (refreshTimerRef.current) {
      clearInterval(refreshTimerRef.current);
      refreshTimerRef.current = null;
    }
  };

  useEffect(() => {
    // Asigna refreshAuthToken a window sin depender del entorno
    if (auth.refreshAuthToken) {
      window.refreshAuthToken = auth.refreshAuthToken;
    }

    return () => {
      delete window.refreshAuthToken;
    };
  }, [auth]);

  useEffect(() => {
    const handleLogin = () => {
      startRefreshTimer();
    };

    const handleLogout = () => {
      stopRefreshTimer();
    };

    window.addEventListener("loginSuccess", handleLogin);
    window.addEventListener("logout", handleLogout);

    const storedToken = Cookies.get("token");
    if (storedToken) {
      setToken(storedToken);
      setRefreshToken(Cookies.get("refreshToken"));
      startRefreshTimer();
    }

    return () => {
      stopRefreshTimer();
      window.removeEventListener("loginSuccess", handleLogin);
      window.removeEventListener("logout", handleLogout);
    };
  }, [auth]);

  return (
    <AuthContext.Provider value={{ ...auth }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth debe ser usado dentro de un AuthProvider");
  }
  return context;
};
