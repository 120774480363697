import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext"; // Importar AuthProvider
import MenuBar from "./components/Menu";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import FormCrearAsociados from "./pages/FormCrearAsociados";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <AuthProvider>
      {" "}
      {/* Envolver toda la aplicación con AuthProvider */}
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MenuBar />
              </PrivateRoute>
            }
          >
            <Route index element={<Home />} />
            <Route path="FormCrearAsociados" element={<FormCrearAsociados />} />
            <Route path="Dashboard" element={<Dashboard />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
