import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Title = styled(Typography)({
  marginBottom: 16,
  color: "blue",
});

const StyledTableContainer = styled(TableContainer)({
  display: "flex",
  justifyContent: "center",
  margin: "0 auto", // Esto asegura que el contenedor tenga un margen automático a los lados
});

const StyledTable = styled(Table)({
  minWidth: 650,
});

const StyledCell = styled(TableCell)({
  fontWeight: "bold",
});

const games = [
  { title: "Halo: Combat Evolved", year: 2001, genre: "Shooter" },
  { title: "Halo 2", year: 2004, genre: "Shooter" },
  { title: "Halo 3", year: 2007, genre: "Shooter" },
  { title: "Halo Wars", year: 2009, genre: "Strategy" },
  { title: "Halo: Reach", year: 2010, genre: "Shooter" },
  { title: "Halo: Spartan Assault", year: 2013, genre: "Shooter" },
  { title: "Halo 5: Guardians", year: 2015, genre: "Shooter" },
  { title: "Halo Wars 2", year: 2017, genre: "Strategy" },
  { title: "Halo: The Master Chief Collection", year: 2014, genre: "Shooter" },
  { title: "Halo Infinite", year: 2021, genre: "Shooter" },
];

const Settings = () => {
  return (
    <div>
      <Title variant="h4">Halo Master Chief</Title>
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledCell>Título</StyledCell>
              <StyledCell>Año</StyledCell>
              <StyledCell>Género</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {games.map((game, index) => (
              <TableRow key={index}>
                <TableCell>{game.title}</TableCell>
                <TableCell>{game.year}</TableCell>
                <TableCell>{game.genre}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </div>
  );
};

export default Settings;
