// src/common/ReactVirtualizedTable.js

import React, { useState, memo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { TableVirtuoso } from "react-virtuoso";
import "../styles/components/ReactVirtualizedTable.css";

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer
      component={Paper}
      {...props}
      ref={ref}
      className="table-container"
    />
  )),
  Table: (props) => <Table {...props} className="table" />,
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

VirtuosoTableComponents.Scroller.displayName = "Scroller";
VirtuosoTableComponents.Table.displayName = "Table";
VirtuosoTableComponents.TableHead.displayName = "TableHead";
VirtuosoTableComponents.TableBody.displayName = "TableBody";

const fixedHeaderContent = (columns, showCheckboxes) => (
  <TableRow>
    {showCheckboxes && (
      <TableCell padding="checkbox" className="checkbox-cell">
        <Checkbox />
      </TableCell>
    )}
    {columns.map((column) => (
      <TableCell
        key={column.dataKey}
        variant="head"
        align={column.numeric ? "right" : "left"}
        className="table-cell"
        style={{ width: column.width }}
      >
        {column.label}
      </TableCell>
    ))}
  </TableRow>
);

const ReactVirtualizedTable = memo(function ({
  columns,
  data,
  showCheckboxes = false,
  customRenderers = {},
}) {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id],
    );
  };

  function rowContent(index, row) {
    return (
      <>
        {showCheckboxes && (
          <TableCell padding="checkbox" className="checkbox-cell">
            <Checkbox
              checked={selectedRows.includes(row.id)}
              onChange={() => handleCheckboxChange(row.id)}
            />
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align={column.numeric ? "right" : "left"}
            className="table-cell"
          >
            {customRenderers[column.dataKey]
              ? customRenderers[column.dataKey](row)
              : row[column.dataKey]}
          </TableCell>
        ))}
      </>
    );
  }

  return (
    <Paper className="table-container">
      <TableVirtuoso
        data={data}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => fixedHeaderContent(columns, showCheckboxes)}
        itemContent={(index, row) => rowContent(index, row)}
      />
    </Paper>
  );
});

ReactVirtualizedTable.displayName = "ReactVirtualizedTable";

export default ReactVirtualizedTable;
