import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import useNitDV from "../../hooks/useNitDV"; // Importa el hook personalizado
import { top100Films } from "../constants"; // Importa la lista de películas

const PersonaJuridica = ({ handleChange, handleFormDataChange }) => {
  const { nit, dv, handleNitChange } = useNitDV(); // Usa el hook personalizado
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [tipoCiuu, setTipoCiuu] = useState(null);

  // Actualiza `formData` cuando cambia un campo específico
  const onFieldChange = (field, value) => {
    handleFormDataChange(field, value);
  };

  return (
    <div className="personal-juridica-section">
      <div className="three-column-grid">
        <FormControl className="input-form-control" required>
          <InputLabel id="age-label" className="input-label">
            Tipo de Documento
          </InputLabel>
          <Select
            labelId="age-label"
            id="age-select-juridica"
            value={tipoDocumento}
            label="Tipo de Documento"
            onChange={(e) => {
              setTipoDocumento(e.target.value);
              handleChange(e);
              onFieldChange("tipoDocumento", e.target.value);
            }}
            className="input-select"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Cédula de Ciudadanía</MenuItem>
            <MenuItem value={20}>Cédula Extranjera</MenuItem>
            <MenuItem value={30}>NIT</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="number-juridica"
          label="Número"
          variant="outlined"
          className="input-textfield"
          required
          type="text"
          value={nit}
          onChange={(e) => {
            handleNitChange(e);
            onFieldChange("numero", e.target.value);
          }}
          inputProps={{ maxLength: 15 }}
        />

        <TextField
          disabled
          id="dv-juridica"
          label="DV"
          variant="outlined"
          value={dv} // Muestra el DV calculado
          className="input-textfield"
          inputProps={{ maxLength: 1 }}
        />
      </div>

      <div className="three-column-grid">
        <TextField
          id="first-name-juridica"
          label="Razón Social"
          variant="outlined"
          className="input-textfield"
          required
          onChange={(e) => onFieldChange("razonSocial", e.target.value)}
        />
        <TextField
          id="second-name-juridica"
          label="Correo"
          variant="outlined"
          className="input-textfield"
          required
          onChange={(e) => onFieldChange("correo", e.target.value)}
        />

        <Autocomplete
          className="input-form-control"
          disablePortal
          options={top100Films} // La lista de opciones
          value={tipoCiuu} // El valor actual
          onChange={(event, value) => {
            setTipoCiuu(value); // Actualizar el estado `ciiu` con el valor seleccionado
            onFieldChange("ciiu", value); // Actualizar el valor en el formulario
          }}
          isOptionEqualToValue={(option, value) =>
            option.label === value.label && option.year === value.year
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="CIIU"
              className="input-select"
              required
              InputLabelProps={{
                className: "input-label",
              }}
              InputProps={{
                ...params.InputProps,
                className: "input-form-control",
              }}
            />
          )}
        />
      </div>

      <div className="two-column-grid">
        <TextField
          disabled
          id="ciiu-completo-juridica"
          label="CIIU Completo"
          variant="outlined"
          defaultValue="Sección del CIIU COMPLETO"
          className="input-textfield"
          required
        />
      </div>
    </div>
  );
};

export default PersonaJuridica;
