// FormVerify.js
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "../styles/components/alertStyle.css";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import EditIcon from "@mui/icons-material/Edit";

const FormVerify = ({ formDataContent, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const content = (
    <div className={`container ${visible ? "visible" : ""}`}>
      <div className="container-verify">
        <h2 className="approval-text-ver">
          Verificación de Datos del Formulario
        </h2>

        {/* Renderiza el contenido específico del formulario */}
        {formDataContent}

        <div className="button-container-veri">
          <Button variant="contained" color="primary" className="save-button">
            <SaveAltIcon />
            Guardar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="edit-button"
            onClick={onClose}
          >
            <EditIcon />
            Editar
          </Button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(content, document.getElementById("modal-root"));
};

export default FormVerify;
