import React from "react";
import loadingGif from "../../src/styles/gifs/barco-de-carga.gif"; // Ajusta la ruta según donde esté tu GIF
import "../../src/styles/components/loading.css"; // Asegúrate de tener un archivo CSS para estilos

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-container">
        <img src={loadingGif} alt="Loading..." className="loader" />
        <p></p>
      </div>
    </div>
  );
};

export default Loading;
