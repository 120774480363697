import React, { useState, useRef, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import PersonaJuridica from "../components/AsociadosForms/PersonaJuridica.js";
import PersonaNatural from "../components/AsociadosForms/PersonaNatural.js";
import Proveedor from "../components/AsociadosForms/Proveedor/Proveedor.js";
import FormVerify from "../hooks/FormVerify";
import "../styles/pages/Asociados.pages.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowDownIcon from "../hooks/ArrowFlotant.js";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AsociadosVerify from "../components/AsociadosForms/AsociadosVerify.js";
import { data as documentData } from "../components/AsociadosForms/Proveedor/TableAdjuntos.js";

const Profile = () => {
  const [showSecondPhone, setShowSecondPhone] = useState(false);
  const [showArrowIcon, setShowArrowIcon] = useState(true);
  const [showFormVerify, setShowFormVerify] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    country: "",
    email: "",
    city: "",
    address: "",
    phone1: "",
    phone2: "",
    tipoDocumento: "",
    numero: "",
    dv: "",
    razonSocial: "",
    correo: "",
    ciiu: "",
    isEmpleado: false,
    isCliente: false,
    isProveedor: false,
    isNatural: true,
    isJuridica: false,
    isProveedorCertificado: false,
    isAutoretenedor: false,
    isAutoretenedorICA: false,
    isResponsableIVA: false,
    metodoPago: "",
    numeroResolucion: "",
    centroCostos: "",
    banco: "",
    tipoCuenta: "",
    tipoProveedor: "",
    claseProveedor: "",
    numeroCuenta: "",
    titularCuenta: "",
    adjuntos: {}, // Convertido a objeto para mapeo directo por id
  });

  const proveedorRef = useRef(null);
  const formDateBasicRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (name, checked) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleFormDataChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAdjuntosChange = (uploadedFiles) => {
    // Actualizar adjuntos en formData
    setFormData((prevData) => ({
      ...prevData,
      adjuntos: uploadedFiles,
    }));
  };

  const handleNaturalChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      isNatural: !prevData.isNatural,
      isJuridica: prevData.isNatural,
    }));
  };

  const handleJuridicaChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      isNatural: prevData.isJuridica,
      isJuridica: !prevData.isJuridica,
    }));
  };

  const isSaveEnabled =
    formData.isEmpleado || formData.isCliente || formData.isProveedor;

  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
    { label: "The Lord of the Rings: The Return of the King", year: 2003 },
  ];

  const scrollToProveedor = () => {
    if (proveedorRef.current) {
      proveedorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setShowArrowIcon(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowArrowIcon(formDateBasicRef.current.scrollTop === 0);
    };

    const formDateBasicElement = formDateBasicRef.current;
    formDateBasicElement.addEventListener("scroll", handleScroll);

    return () => {
      formDateBasicElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    setShowFormVerify(true); // Mostrar el modal de verificación
  };

  // Memoize components to prevent unnecessary re-renders
  const personaNaturalComponent = useMemo(
    () => (
      <PersonaNatural
        handleChange={handleChange}
        handleFormDataChange={handleFormDataChange}
      />
    ),
    [],
  );

  const personaJuridicaComponent = useMemo(
    () => (
      <PersonaJuridica
        handleChange={handleChange}
        handleFormDataChange={handleFormDataChange}
      />
    ),
    [],
  );

  const proveedorComponent = useMemo(
    () => (
      <Proveedor
        parentRef={formDateBasicRef}
        handleFormDataChange={handleFormDataChange}
        handleAdjuntosChange={handleAdjuntosChange} // Pasar función para actualizar adjuntos
      />
    ),
    [],
  );

  return (
    <div className="creation-form-container">
      {showFormVerify && (
        <FormVerify
          formDataContent={
            <AsociadosVerify formData={formData} documentData={documentData} />
          }
          onClose={() => setShowFormVerify(false)}
        />
      )}
      <div className="form-header">
        <h1 className="form-title">
          Formulario Creación de Asociados de Negocio
        </h1>
        <IconButton
          aria-label="back"
          className="back-button"
          onClick={() => setShowFormVerify(true)}
        >
          <KeyboardReturnIcon />
        </IconButton>
      </div>
      <form className="form-content" onSubmit={handleSave}>
        <div ref={formDateBasicRef} className="form-date-basic">
          <div className="person-type">
            <span className="person-type-label">Persona:</span>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isNatural}
                onChange={handleNaturalChange}
              />
              <span className="checkbox-text">Natural</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isJuridica}
                onChange={handleJuridicaChange}
              />
              <span className="checkbox-text">Jurídica</span>
            </label>
          </div>
          {formData.isNatural
            ? personaNaturalComponent
            : personaJuridicaComponent}
          <div className="sucursal-section">
            <h1 className="sucursal-title">Sucursal</h1>
            <div className="two-column-grid">
              <TextField
                id="name-sucursal"
                label="Nombre"
                variant="outlined"
                className="input-textfield"
                required
                name="name"
                onChange={handleChange}
              />
              <Autocomplete
                className="input-form-control"
                disablePortal
                options={top100Films}
                value={formData.country || null} // Asegura que `city` nunca sea `""`
                onChange={(event, newValue) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    country: newValue || null, // Asegura que el valor siempre sea un objeto o `null`
                  }))
                }
                isOptionEqualToValue={(option, value) => {
                  // Maneja el caso donde `value` es `null` o `""`
                  if (!value) return false;
                  return (
                    option.label === value.label && option.year === value.year
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="País"
                    className="input-select"
                    required
                    InputLabelProps={{
                      className: "input-label",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      className: "input-form-control",
                    }}
                  />
                )}
              />
            </div>
            <div className="two-column-grid">
              <TextField
                id="suc-email"
                label="Correo"
                variant="outlined"
                className="input-textfield"
                required
                name="email"
                onChange={handleChange}
              />
              <Autocomplete
                className="input-form-control"
                disablePortal
                options={top100Films}
                value={formData.city || null} // Asegura que `city` nunca sea `""`
                onChange={(event, newValue) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    city: newValue || null, // Asegura que el valor siempre sea un objeto o `null`
                  }))
                }
                isOptionEqualToValue={(option, value) => {
                  // Maneja el caso donde `value` es `null` o `""`
                  if (!value) return false;
                  return (
                    option.label === value.label && option.year === value.year
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ciudad"
                    className="input-select"
                    required
                    InputLabelProps={{
                      className: "input-label",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      className: "input-form-control",
                    }}
                  />
                )}
              />
            </div>
            <div className="three-column-grid">
              <TextField
                id="direccion-sucursal"
                label="Dirección"
                variant="outlined"
                className="input-textfield item-1"
                required
                name="address"
                onChange={handleChange}
              />
              <div
                className={`phone-input-containervis ${
                  showSecondPhone ? "visible item-2" : "invisible item-2"
                }`}
              >
                <TextField
                  id="second-phone-profile"
                  label="Teléfono 2"
                  variant="outlined"
                  className="input-textfield"
                  type="text"
                  inputProps={{ maxLength: 15 }}
                  name="phone2"
                  onChange={handleChange}
                />
              </div>
              <div className="phone-input-container item-3">
                <TextField
                  id="phone-profile"
                  label="Teléfono"
                  variant="outlined"
                  className="input-textfield responsive-textf-tel"
                  required
                  type="text"
                  inputProps={{ maxLength: 15 }}
                  name="phone1"
                  onChange={handleChange}
                />
                <div className="add-phone-button-container">
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => setShowSecondPhone(!showSecondPhone)}
                    className="add-phone-button"
                  >
                    <AddIcon />
                  </Fab>
                </div>
              </div>
            </div>
            {formData.isProveedor && showArrowIcon && (
              <div className="icon-arrowdown-aso" onClick={scrollToProveedor}>
                <ArrowDownIcon />
              </div>
            )}
          </div>
          {formData.isProveedor && (
            <div ref={proveedorRef}>{proveedorComponent}</div>
          )}
        </div>
        <div className="bottom-content">
          <div className="bottom-checkboxes">
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isEmpleado}
                onChange={(e) =>
                  handleCheckboxChange("isEmpleado", e.target.checked)
                }
              />
              <span className="checkbox-text">Empleado</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isCliente}
                onChange={(e) =>
                  handleCheckboxChange("isCliente", e.target.checked)
                }
              />
              <span className="checkbox-text">Cliente</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isProveedor}
                onChange={(e) =>
                  handleCheckboxChange("isProveedor", e.target.checked)
                }
              />
              <span className="checkbox-text">Proveedor</span>
            </label>
          </div>
        </div>
        <div className="save-button-container">
          <Button
            variant="contained"
            type="submit"
            className="save-button"
            disabled={!isSaveEnabled}
          >
            <SaveAltIcon />
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Profile;
