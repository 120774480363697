// useNitDV.js
import { useState } from "react";
import calcularDV from "../hooks/Operaciones"; // Importa la función de cálculo

const useNitDV = () => {
  const [nit, setNit] = useState("");
  const [dv, setDv] = useState(""); // Estado para el DV

  const handleNitChange = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Acepta solo dígitos
    setNit(input);

    // Calcula el DV si el NIT no está vacío
    if (input) {
      const calculatedDv = calcularDV(input);
      setDv(calculatedDv);
    } else {
      setDv(""); // Resetea el DV si no hay NIT
    }
  };

  return { nit, dv, handleNitChange };
};

export default useNitDV;
