import React from "react";
import "../styles/stylesOficial.css";

const Home = () => {
  return (
    <div className="containerGeneral">
      <div className="containerframe1">
        <h2 className="titless">Escoje una Empresa</h2>
        <div className="buttoncontainerempresa">
          <button className="companybuttonempresa">Pasar Ltda</button>
          <button className="companybuttonempresa">Alpasar S.A.S</button>
        </div>
      </div>
    </div>
  );
};

export default Home;
