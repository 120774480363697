import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import useNitDV from "../../hooks/useNitDV";
import { top100Films } from "../constants";

const PersonaNatural = ({ handleChange, handleFormDataChange }) => {
  const { nit, dv, handleNitChange } = useNitDV();
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [tipoCiuu, setTipoCiuu] = useState(null);

  const onFieldChange = (field, value) => {
    handleFormDataChange(field, value);
  };

  return (
    <div className="personal-natural-section">
      <div className="three-column-grid">
        <FormControl className="input-form-control" required>
          <InputLabel id="age-label" className="input-label">
            Tipo de Documento
          </InputLabel>
          <Select
            labelId="age-label"
            id="age-select-natural"
            value={tipoDocumento}
            label="Tipo de Documento"
            onChange={(e) => {
              setTipoDocumento(e.target.value);
              handleChange(e);
              onFieldChange("tipoDocumento", e.target.value);
            }}
            className="input-select"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Cédula de Ciudadanía</MenuItem>
            <MenuItem value={20}>Cédula Extranjeria</MenuItem>
            <MenuItem value={30}>NIT</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="number-natural"
          label="Número"
          variant="outlined"
          className="input-textfield"
          required
          type="text"
          value={nit}
          onChange={(e) => {
            handleNitChange(e);
            onFieldChange("numero", e.target.value);
          }}
          inputProps={{ maxLength: 15 }}
        />

        <TextField
          disabled
          id="phone-natural"
          label="DV"
          variant="outlined"
          value={dv}
          className="input-textfield"
          inputProps={{ maxLength: 1 }}
        />
      </div>

      <div className="three-column-grid">
        <TextField
          id="first-name-natural"
          label="Primer Nombre"
          variant="outlined"
          className="input-textfield"
          required
          onChange={(e) => onFieldChange("primerNombre", e.target.value)}
        />
        <TextField
          id="second-name-natural"
          label="Segundo Nombre"
          variant="outlined"
          className="input-textfield"
          onChange={(e) => onFieldChange("segundoNombre", e.target.value)}
        />
        <Autocomplete
          className="input-form-control"
          disablePortal
          options={top100Films} // La lista de opciones
          value={tipoCiuu} // El valor actual
          onChange={(event, value) => {
            setTipoCiuu(value); // Actualizar el estado `ciiu` con el valor seleccionado
            onFieldChange("ciiu", value); // Actualizar el valor en el formulario
          }}
          isOptionEqualToValue={(option, value) =>
            option.label === value.label && option.year === value.year
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="CIIU"
              className="input-select"
              required
              InputLabelProps={{
                className: "input-label",
              }}
              InputProps={{
                ...params.InputProps,
                className: "input-form-control",
              }}
            />
          )}
        />
      </div>

      <div className="three-column-grid">
        <TextField
          id="first-apellido-natural"
          label="Primer Apellido"
          variant="outlined"
          className="input-textfield"
          required
          onChange={(e) => onFieldChange("primerApellido", e.target.value)}
        />
        <TextField
          id="second-apellido-natural"
          label="Segundo Apellido"
          variant="outlined"
          className="input-textfield"
          onChange={(e) => onFieldChange("segundoApellido", e.target.value)}
        />

        <TextField
          disabled
          id="ciiu-completo-natural"
          label="CIIU Completo"
          variant="outlined"
          defaultValue="Sección del CIIU COMPLETO"
          className="input-textfield"
          required
        />
      </div>

      <div className="two-column-grid">
        <TextField
          id="correo-natural"
          label="Correo"
          variant="outlined"
          className="input-textfield"
          required
          onChange={(e) => onFieldChange("correo", e.target.value)}
        />
      </div>
    </div>
  );
};

export default PersonaNatural;
