// calcularDV.js

/**
 * Calcula el dígito de verificación (DV) para un número de identificación tributaria (NIT) en Colombia.
 * @param {string} nit - El número de identificación tributaria (NIT) como una cadena de texto.
 * @returns {number} - El dígito de verificación correspondiente.
 */
function calcularDV(nit) {
  // Pesos definidos para el cálculo, de derecha a izquierda, de acuerdo con la DIAN
  const pesos = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
  let suma = 0;

  // Itera a través de cada dígito del NIT, aplicando los pesos y sumando los productos
  for (let i = 0; i < nit.length; i++) {
    const digito = parseInt(nit.charAt(nit.length - 1 - i), 10); // Obtiene cada dígito de derecha a izquierda
    suma += digito * pesos[i];
  }

  // Calcula el residuo de la suma al dividir por 11
  const residuo = suma % 11;

  // Aplica la regla para el dígito de verificación
  return residuo < 2 ? residuo : 11 - residuo;
}

// Exporta la función para que pueda ser importada en otros archivos
module.exports = calcularDV;
