// AsociadosVerify.js
import React from "react";

const AsociadosVerify = ({ formData, documentData }) => {
  // Verifica si hay datos en la sección "Datos del Proveedor"
  const hasProviderData =
    formData.isProveedorCertificado ||
    formData.isAutoretenedor ||
    formData.isAutoretenedorICA ||
    formData.isResponsableIVA ||
    formData.tipoProveedor ||
    formData.claseProveedor ||
    formData.metodoPago ||
    formData.numeroResolucion ||
    formData.centroCostos ||
    formData.banco ||
    formData.tipoCuenta ||
    formData.numeroCuenta ||
    formData.titularCuenta;

  // Verifica si hay algún adjunto presente
  const hasAttachments =
    formData.adjuntos && Object.keys(formData.adjuntos).length > 0;

  return (
    <div className="form-data">
      <h3 className="section-title-ver">Datos de la Persona</h3>
      {formData.isNatural && (
        <p>
          <strong>Natural:</strong> Sí
        </p>
      )}
      {formData.isJuridica && (
        <p>
          <strong>Jurídica:</strong> Sí
        </p>
      )}
      {formData.tipoDocumento && (
        <p>
          <strong>Tipo de Documento:</strong> {formData.tipoDocumento}
        </p>
      )}
      {formData.numero && (
        <p>
          <strong>Número:</strong> {formData.numero}
        </p>
      )}
      {formData.dv && (
        <p>
          <strong>DV:</strong> {formData.dv}
        </p>
      )}
      {formData.razonSocial && (
        <p>
          <strong>Razón Social:</strong> {formData.razonSocial}
        </p>
      )}
      {formData.primerNombre && (
        <p>
          <strong>Primer Nombre:</strong> {formData.primerNombre}
        </p>
      )}
      {formData.segundoNombre && (
        <p>
          <strong>Segundo Nombre:</strong> {formData.segundoNombre}
        </p>
      )}
      {formData.primerApellido && (
        <p>
          <strong>Primer Apellido:</strong> {formData.primerApellido}
        </p>
      )}
      {formData.segundoApellido && (
        <p>
          <strong>Segundo Apellido:</strong> {formData.segundoApellido}
        </p>
      )}
      {formData.ciiu && (
        <p>
          <strong>CIIU:</strong> {formData.ciiu.label || formData.ciiu}
        </p>
      )}
      {formData.correo && (
        <p>
          <strong>Correo:</strong> {formData.correo}
        </p>
      )}
      <h3 className="section-title-ver">Sucursal</h3>
      {formData.name && (
        <p>
          <strong>Nombre:</strong> {formData.name}
        </p>
      )}
      {formData.email && (
        <p>
          <strong>Email:</strong> {formData.email}
        </p>
      )}{" "}
      {formData.country && (
        <p>
          <strong>País:</strong> {formData.country.label || formData.country}
        </p>
      )}
      {formData.city && (
        <p>
          <strong>Ciudad:</strong> {formData.city.label || formData.city}
        </p>
      )}
      {formData.address && (
        <p>
          <strong>Dirección:</strong> {formData.address}
        </p>
      )}
      {formData.phone1 && (
        <p>
          <strong>Teléfono 1:</strong> {formData.phone1}
        </p>
      )}
      {formData.phone2 && (
        <p>
          <strong>Teléfono 2:</strong> {formData.phone2}
        </p>
      )}
      <h3 className="section-title-ver">Roles Seleccionados</h3>
      {formData.isEmpleado && (
        <p>
          <strong>Empleado:</strong> Sí
        </p>
      )}
      {formData.isCliente && (
        <p>
          <strong>Cliente:</strong> Sí
        </p>
      )}
      {formData.isProveedor && (
        <p>
          <strong>Proveedor:</strong> Sí
        </p>
      )}
      {/* Información del Proveedor */}
      {hasProviderData && (
        <>
          <h3 className="section-title-ver">Datos del Proveedor</h3>
          {formData.isProveedorCertificado && (
            <p>
              <strong>Proveedor Certificado:</strong> Sí
            </p>
          )}
          {formData.isAutoretenedor && (
            <p>
              <strong>Autoretenedor:</strong> Sí
            </p>
          )}
          {formData.isAutoretenedorICA && (
            <p>
              <strong>Autoretenedor ICA:</strong> Sí
            </p>
          )}
          <p>
            <strong>Responsable IVA:</strong>{" "}
            {formData.isResponsableIVA ? "Sí" : "No"}
          </p>
          {formData.tipoProveedor && (
            <p>
              <strong>Tipo de Proveedor:</strong> {formData.tipoProveedor}
            </p>
          )}
          {formData.claseProveedor && (
            <p>
              <strong>Clase de Proveedor:</strong> {formData.claseProveedor}
            </p>
          )}
          {formData.metodoPago && (
            <p>
              <strong>Método de Pago:</strong> {formData.metodoPago}
            </p>
          )}
          {formData.numeroResolucion && (
            <p>
              <strong>Número de Resolución:</strong> {formData.numeroResolucion}
            </p>
          )}
          {formData.centroCostos && (
            <p>
              <strong>Centro de Costos:</strong>{" "}
              {formData.centroCostos.label || formData.centroCostos}
            </p>
          )}
          {formData.banco && (
            <p>
              <strong>Banco:</strong> {formData.banco}
            </p>
          )}
          {formData.tipoCuenta && (
            <p>
              <strong>Tipo de Cuenta:</strong> {formData.tipoCuenta}
            </p>
          )}
          {formData.numeroCuenta && (
            <p>
              <strong>Número de Cuenta:</strong> {formData.numeroCuenta}
            </p>
          )}
          {formData.titularCuenta && (
            <p>
              <strong>Titular de la Cuenta:</strong> {formData.titularCuenta}
            </p>
          )}
        </>
      )}
      {/* Mostrar Archivos Adjuntos */}
      {hasAttachments && (
        <>
          <h3 className="section-title-ver">Archivos Adjuntos</h3>
          {Object.keys(formData.adjuntos).map((key) => {
            const documentInfo = documentData
              ? documentData.find((doc) => doc.id === parseInt(key))
              : null;
            const documentName = documentInfo
              ? documentInfo.document
              : "Documento desconocido";

            return (
              <p key={key}>
                <strong>{documentName}:</strong>{" "}
                <a
                  href={formData.adjuntos[key].url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formData.adjuntos[key].name}
                </a>
              </p>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AsociadosVerify;
