import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import { top100Films } from "../../constants.js";
import TableAdjuntos from "./TableAdjuntos.js";
import ArrowDownIcon from "../../../hooks/ArrowFlotant.js";
import ReactVirtualizedTable from "../../ReactVirtualizedTable.js";

const Proveedor = ({ handleFormDataChange, parentRef }) => {
  const [isAutoretenedor, setIsAutoretenedor] = useState(false);
  const [showArrowIcon, setShowArrowIcon] = useState(false);
  const { customRenderers, columns, data, uploadedFiles } = TableAdjuntos();
  const [metodoPago, setMetodoPago] = useState(""); // Valor inicial válido
  const [banco, setBanco] = useState("");
  const [tipoCuenta, setTipoCuenta] = useState("");
  const [claseProveedor, setClaseProveedor] = useState("");
  const [tipoProveedor, setTipoProveedor] = useState("");

  // Sincronizar archivos adjuntos con el estado general del formulario
  useEffect(() => {
    handleFormDataChange("adjuntos", uploadedFiles);
  }, [uploadedFiles, handleFormDataChange]);

  const scrollToTop = () => {
    if (parentRef && parentRef.current) {
      parentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (parentRef && parentRef.current) {
        const isAtBottom =
          parentRef.current.scrollHeight - parentRef.current.scrollTop ===
          parentRef.current.clientHeight;
        setShowArrowIcon(isAtBottom);
      }
    };

    if (parentRef && parentRef.current) {
      parentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (parentRef && parentRef.current) {
        parentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [parentRef]);

  return (
    <div className="proveedor-container">
      {showArrowIcon && (
        <div className="icon-arrowdown-aso" onClick={scrollToTop}>
          <ArrowDownIcon />
        </div>
      )}
      <div className="proveedor-section">
        <h1 className="sucursal-title">Proveedor</h1>
        <div className="person-type">
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              onChange={(e) =>
                handleFormDataChange("isProveedorCertificado", e.target.checked)
              }
            />
            <span className="checkbox-text">Proveedor Certificado</span>
          </label>
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={isAutoretenedor}
              onChange={(e) => {
                setIsAutoretenedor(e.target.checked);
                handleFormDataChange("isAutoretenedor", e.target.checked);
              }}
            />
            <span className="checkbox-text">Autoretenedor</span>
          </label>
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              onChange={(e) =>
                handleFormDataChange("isAutoretenedorICA", e.target.checked)
              }
            />
            <span className="checkbox-text">Autoretenedor ICA</span>
          </label>
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              onChange={(e) =>
                handleFormDataChange("isResponsableIVA", e.target.checked)
              }
            />
            <span className="checkbox-text">Responsable IVA</span>
          </label>
        </div>

        <div className="three-column-grid">
          <FormControl className="input-form-control" required>
            <InputLabel id="metodo-pago-label" className="input-label">
              Método de Pago
            </InputLabel>
            <Select
              labelId="metodo-pago-label"
              id="metodo-pago-select"
              value={metodoPago}
              onChange={(e) => {
                setMetodoPago(e.target.value);
                handleFormDataChange("metodoPago", e.target.value);
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Credito 30 Dias">Credito 30 Dias</MenuItem>
              <MenuItem value="Credito 60 Dias">Credito 60 Dias</MenuItem>
              <MenuItem value="Contado">Contado</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="input-form-control" required>
            <InputLabel id="clase-proveedor-label" className="input-label">
              Clase de Proveedor
            </InputLabel>
            <Select
              labelId="clase-proveedor-label"
              id="clase-proveedor-select"
              value={claseProveedor}
              onChange={(e) => {
                setClaseProveedor(e.target.value);
                handleFormDataChange("claseProveedor", e.target.value);
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Proveedores Nacionales">
                Proveedores Nacionales
              </MenuItem>
              <MenuItem value="Pago de Agentes">Pago de Agentes</MenuItem>
              <MenuItem value="Devolucion de Deposito de Contenedor">
                Devolucion de Deposito de Contenedor
              </MenuItem>
              <MenuItem value="Proveedores Administrativos">
                Proveedores Administrativos
              </MenuItem>
              <MenuItem value="Cuentas por Pagar a Aerolineas">
                Cuentas por Pagar a Aerolineas
              </MenuItem>
              <MenuItem value="Cuentas por Pagar Seguro">
                Cuentas por Pagar Seguro
              </MenuItem>
              <MenuItem value="Pagos de Seguridad Social">
                Pagos de Seguridad Social
              </MenuItem>
            </Select>
          </FormControl>

          <Autocomplete
            className="input-form-control"
            disablePortal
            options={top100Films}
            onChange={(event, value) =>
              handleFormDataChange("centroCostos", value?.label || "")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Centro de Costos"
                className="input-select"
                InputLabelProps={{
                  className: "input-label",
                }}
                InputProps={{
                  ...params.InputProps,
                  className: "input-form-control",
                }}
              />
            )}
          />
        </div>

        <div className="three-column-grid">
          <FormControl className="input-form-control" required>
            <InputLabel id="banco-label" className="input-label">
              Banco
            </InputLabel>
            <Select
              labelId="banco-label"
              id="banco-select"
              value={banco}
              onChange={(e) => {
                setBanco(e.target.value);
                handleFormDataChange("banco", e.target.value);
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Bancolombia">Bancolombia</MenuItem>
              <MenuItem value="Davivienda">Davivienda</MenuItem>
              <MenuItem value="Itaú">Itaú</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="input-form-control" required>
            <InputLabel id="tipo-cuenta-label" className="input-label">
              Tipo de Cuenta
            </InputLabel>
            <Select
              labelId="tipo-cuenta-label"
              id="tipo-cuenta-select"
              value={tipoCuenta}
              onChange={(e) => {
                setTipoCuenta(e.target.value);
                handleFormDataChange("tipoCuenta", e.target.value);
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Cuenta Corriente">Cuenta Corriente</MenuItem>
              <MenuItem value="Cuenta de Ahorros">Cuenta de Ahorros</MenuItem>
              <MenuItem value="Cuenta Exterior">Cuenta Exterior</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="input-form-control" required>
            <InputLabel id="tipo-proveedor-label" className="input-label">
              Tipo de Proveedor
            </InputLabel>
            <Select
              labelId="tipo-proveedor-label"
              id="tipo-proveedor-select"
              value={tipoProveedor}
              onChange={(e) => {
                setTipoProveedor(e.target.value);
                handleFormDataChange("tipoProveedor", e.target.value);
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Proveedores Administrativos">
                Proveedores Administrativos
              </MenuItem>
              <MenuItem value="Aportes de Nomina">Aportes de Nomina</MenuItem>
              <MenuItem value="Prov Compañias Vinculadas">
                Prov Compañias Vinculadas
              </MenuItem>
              <MenuItem value="Prov. Financieros">Prov. Financieros</MenuItem>
              <MenuItem value="Prov- Impuestos">Prov- Impuestos</MenuItem>
              <MenuItem value="Nomina">Nomina</MenuItem>
              <MenuItem value="Proveedores Operativos">
                Proveedores Operativos
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="three-column-grid">
          <TextField
            id="numero-cuenta"
            label="Número de Cuenta"
            variant="outlined"
            className="input-textfield item-1"
            required
            onChange={(e) =>
              handleFormDataChange("numeroCuenta", e.target.value)
            }
          />

          <TextField
            id="titular-cuenta"
            label="Titular de la Cuenta"
            variant="outlined"
            className="input-textfield item-3"
            required
            onChange={(e) =>
              handleFormDataChange("titularCuenta", e.target.value)
            }
          />

          {isAutoretenedor && (
            <TextField
              id="numero-resolucion"
              label="Número de Resolución"
              variant="outlined"
              className="input-textfield item-2"
              required
              onChange={(e) =>
                handleFormDataChange("numeroResolucion", e.target.value)
              }
            />
          )}
        </div>
      </div>

      <ReactVirtualizedTable
        columns={columns}
        data={data}
        customRenderers={customRenderers}
      />
    </div>
  );
};

export default Proveedor;
