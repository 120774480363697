import React, { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { CheckCircle, OctagonX, CircleAlert, Check, X } from "lucide-react";
import "../styles/components/alertStyle.css";

export default function AlertComponent({ type, onClose }) {
  const [visible, setVisible] = useState(false);
  const { forcelogout } = useAuth(); // Obtén la función forcelogout del contexto

  useEffect(() => {
    const timerShow = setTimeout(() => {
      setVisible(true);
    }, 100); // 100ms para mostrar

    let timerHide;
    let timerClose;

    if (type !== "sessionActive") {
      timerHide = setTimeout(() => {
        setVisible(false);
      }, 2000); // 2000ms para duración en pantalla

      timerClose = setTimeout(() => {
        onClose(); // Llama a onClose después de 3000ms en total
      }, 3000); // 3000ms para ocultar completamente
    }

    return () => {
      clearTimeout(timerShow);
      clearTimeout(timerHide);
      clearTimeout(timerClose);
    };
  }, [type, onClose]); // Asegúrate de incluir type y onClose en las dependencias

  const isSuccess = type === "success";
  const isSaved = type === "saved";
  const isGenerated = type === "generated";
  const isSessionActive = type === "sessionActive";

  const handleButton1Click = () => {
    setVisible(false); // Oculta la alerta inmediatamente
    // Iniciar el temporizador para cerrar la alerta
    setTimeout(() => {
      onClose(); // Llama a onClose después de 3000ms
    }, 1000); // 3000ms para ocultar completamente
  };

  const handleButton2Click = async () => {
    const response = await forcelogout(); // Llama a la función forcelogout

    if (response.ok) {
      onClose(); // Cierra la alerta después del cierre de sesión
    } else {
      // Aquí puedes manejar el error si es necesario
    }
  };

  return (
    <div className={`container ${visible ? "visible" : ""}`}>
      <div
        className={`approval-box ${isSessionActive ? "session-active" : ""}`}
      >
        {isSuccess || isSaved || isGenerated ? (
          <CheckCircle className="check-icon" />
        ) : isSessionActive ? (
          <CircleAlert className="session-icon" />
        ) : (
          <OctagonX className="error-icon" />
        )}
        <p
          className={
            isSuccess || isSaved || isGenerated
              ? "approval-text"
              : isSessionActive
                ? "session-text"
                : "error-text"
          }
        >
          {isSuccess
            ? "Orden Aprobada!"
            : isSaved
              ? "Guardado Exitoso!"
              : isGenerated
                ? "Orden Generada!"
                : isSessionActive
                  ? "! Tienes otra Sesión Activa ¡"
                  : "Orden Rechazada!"}
        </p>
        {isSessionActive && (
          <div className="button-container">
            <button onClick={handleButton1Click} className="alert-button red">
              <X className="icon" />
              Volver al Login
            </button>
            <button onClick={handleButton2Click} className="alert-button green">
              <Check className="icon" />
              Cerrar Sesión Activa
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
